import React from "react"
import JanowoHeader from "../../../components/estates/janowo/janowoHeader"
import Description from "../../../components/estates/janowo/description"
import Visual from "../../../components/estates/janowo/visual"
import Localization from "../../../components/estates/janowo/localization"
import EstatePlan from "../../../components/estates/janowo/estatePlan"
import LogBuilding from "../../../components/estates/logBuilding"
import Layout from "../../../components/layout"
import Helmet from "react-helmet"
import Modal from "../../../components/popup2/popup"
import { logs } from "@/data/diary/buildingLogsOJP"
import { parkingGallery } from "@/data/diary/parkingsOJP"
import wizImage from "@/images/z-8/z-8b.jpg"
import JanowoTwoVisual from "@/components/estates/janowotwo/janowoTwoVisual"

import Video from "@/components/estates/video"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import EstateHeader from "@/components/estates/estateHeader"

function Janowo({ location }) {
  const scrollDestination =
    location && location.state && location.state.position

  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Janowo Park</title>
      </Helmet>
      <Layout>
        <EstateHeader
          title={`Osiedle Janowo Park`}
          place={"RUMIA, UL. BŁOŃ JANOWSKICH"}
          done
          noButton
        />

        <JanowoTwoVisual image={wizImage} alt={`Janowo Park Wizualizacja`} />
        <JanowoHeader
          scrollDestination={scrollDestination}
          location={location}
        />
        <Description />

        <Visual />
        <EstatePlan scrollDestination={scrollDestination} />
        <Localization estate={"janowo"} />
        {/*<Virtual />*/}
        <Video video="https://www.youtube.com/embed/p4HjKrFqVtQ" />
        <LogBuilding
          id="parking"
          title="Hale garażowe i komórki lokatorskie budynki B1-B10"
          logs={parkingGallery}
          anchor={`/pl/oferta/osiedle-janowo-park/#parking`}
        />
        <LogBuilding
          logs={logs}
          anchor={`/pl/oferta/osiedle-janowo-park/#diary`}
        />
        <EstateDisclaimer />
        <Modal />
      </Layout>
    </>
  )
}

export default Janowo
